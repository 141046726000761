import ImgComp from 'assets/images/content/frauencoach-firmen.jpeg'
import ImgPriv from 'assets/images/content/frauencoach-privatpersonen.jpeg'
import ImgCourse from 'assets/images/content/frauencoach-seminare.jpeg'
import {
  BlogList, Button, Card, CardContent, CardImage, Container, Link, Section,
} from 'atoms'
import { ENTRIES as BLOG_ENTRIES } from 'BlogEntries'
import Image from 'next/image'
import { ContactCard } from 'organisms'
import { FC, useEffect, useState } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { useShowContactModal } from 'services/ContactModal'
import { useWindowDimensions } from 'services/layout'

import Styles from './Home.module.sass'

const linkedEntries = [
  BLOG_ENTRIES[0],
  BLOG_ENTRIES[17],
  BLOG_ENTRIES[12],
]
const linkedEntries2 = [
  BLOG_ENTRIES[13],
  BLOG_ENTRIES[1],
  BLOG_ENTRIES[16],
]

const PageContentHome: FC<unknown> = () => {
  const { width: windowWidth } = useWindowDimensions()
  const [cardImageHeight, setCardImageHeight] = useState(250)
  const showContactModal = useShowContactModal()

  // We cannot use useMemo here since it produces a
  // hydration error:
  // https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    if (windowWidth >= 1200) {
      setCardImageHeight(225)
      return
    }
    if (windowWidth >= 992) {
      setCardImageHeight(175)
      return
    }
    if (windowWidth >= 768) {
      setCardImageHeight(125)
      return
    }
    setCardImageHeight(250)
  }, [windowWidth])

  return (
    <>
      <Section background='vintage'>
        <Container className={Styles.containerVideo}>
          <iframe
            width="960"
            height="540"
            src="https://www.youtube-nocookie.com/embed/gZOIDHwKMlo?autoplay=1&color=white&controls=0&rel=0&fs=1&hl=de&showinfo=0&origin=https://www.frauencoach.jetzt"
            allowFullScreen
            title="YouTube - Selbstliebe für Glücksrebellinnen"
            frameBorder="0"
            allow="accelerometer;
            autoplay;
            clipboard-write;
            encrypted-media;
            gyroscope;
            picture-in-picture"
          />
        </Container>
        <Container className={`mt-4 ${Styles.containerVideoActions}`}>
          <Button
            href="/angebot-privatpersonen"
            type="link"
            theme="default"
            size='large'
            className="d-none d-sm-inline-block"
          >
            1:1 Coaching&nbsp;<HiChevronDoubleRight />
          </Button>
          <Button href="/angebot-privatpersonen" type="link" theme="green" className="d-flex d-sm-none">
            1:1 Coaching&nbsp;<HiChevronDoubleRight />
          </Button>
          &nbsp;&nbsp;
          <Button
            href="/lebensart-akademie"
            type="link"
            theme="green"
            size='large'
            className="d-none d-sm-inline-block"
          >
            Zu den Onlinekursen&nbsp;<HiChevronDoubleRight />
          </Button>
          <Button href="/lebensart-akademie" type="link" theme="green" className="d-flex d-sm-none">
            Zu den Onlinekursen&nbsp;<HiChevronDoubleRight />
          </Button>
        </Container>
      </Section>
      <Section background='rose'>
        <Container>
          <h1 className="text-center">
            Lebensfragen klären - Krisen meistern
          </h1>
          <div className={Styles.cardsRow}>
            <Card href="/angebot-privatpersonen">
              <CardImage
                first
                labelColor="brown"
                label="Privatpersonen"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgPriv}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Privatpersonen"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Ihr Schlüssel für ein gelingendes und selbstbestimmtes Leben in Balance. Ich bin an Ihrer Seite bei Veränderungsprozessen und in akuten Krisenzeiten.
                </p>
              </CardContent>
            </Card>
            <Card href="/angebot-firmen">
              <CardImage
                first
                labelColor="blue"
                label="Firmen"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgComp}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Firmen"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Maßgeschneiderte Angebote im Rahmen der betrieblichen Gesundheitsförderung und der Frauenförderung.
                </p>
              </CardContent>
            </Card>
            <Card href="/seminare-workshops">
              <CardImage
                first
                labelColor="green"
                label="Seminare &amp; Workshops"
                height={cardImageHeight}
                image={(
                  <Image
                    src={ImgCourse}
                    placeholder="blur"
                    layout="fill"
                    objectFit="cover"
                    alt="FrauenCoach für Seminare"
                  />
                )}
              />
              <CardContent last>
                <p className='text-smaller'>
                  {/* eslint-disable-next-line max-len */}
                  Gebündelte Kompetenz-Gemeinsam lernen. Exklusive Konzepte für Frauen für private und berufliche Kontexte.
                </p>
              </CardContent>
            </Card>
          </div>
          <ContactCard />
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Das Fundament jetzt legen</h2>
          <p>
            {`Alles Gute beginnt mit der Beziehung zu sich selbst. Einer wertschätzenden und respektvollen Beziehung selbstverständlich. Und da haben viele von uns Frauen ein wenig Nachholbedarf.`}
          </p>
          <p>
            {`Vielleicht stellen Sie sich die folgenden Fragen: Wie geht das eigentlich mit der Selbstliebe? Darf ich das überhaupt? Ist das nicht egoistisch? Lieben mich dann die anderen überhaupt noch? Was denken die anderen dann über mich? usw. usw.`}
          </p>
          <p>
            {`Wenn Sie sich diese oder ähnliche Fragen stellen, könnten meine Onlinekurse für Sie interessant sein. Oder Sie gehen mit mir in ein 1:1 Coaching, individuell für Sie maßgeschneidert.`}
          </p>
          <p className='text-center my-5'>
            <Button href="/lebensart-akademie" type="link" theme="blue">
              Lebensart Akademie / Onlinekurse
            </Button>
            &nbsp;&nbsp;
            <Button onClick={showContactModal} theme="green">
              Unverbindlich Erstgespräch anfragen
            </Button>
          </p>
          <p className='text-center text-bold'>
            Aktivieren Sie Ihre Selbstliebe.
            <br/>
            Sie ist der Schlüssel für Ihr zufriedenes Leben.
            <br/>
            Welch schöneres Geschenk könnten Sie sich machen.
          </p>
        </Container>
      </Section>
      <Section background='white'>
        <Container>
          <h2 className='mt-0'>Interessante Artikel</h2>
          <BlogList blogEntries={linkedEntries} />
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Ihr Leben nach Ihren Wünschen und Bedürfnissen</h2>
          <p>
            {`Wie wäre es für Sie, wenn Sie Ihr Leben nach ihren Vorstellungen und Wünschen leben würden? Das würde ihnen gefallen? Dann starten Sie jetzt. Ich zeige Ihnen den Weg.`}&nbsp;
            <Button onClick={showContactModal} theme="link">
              Kontaktieren Sie mich gerne unverbindlich.
            </Button>
          </p>

          <h3>Warum ich das kann?</h3>
          <p>
            {`Ich habe mich mit den realen Lebenswelten von Frauen professionell auseinandergesetzt und kenne sie aus erster Hand.`}&nbsp;
            <em>
              (Lest weiterführend gerne meine Beiträge&nbsp;
              <Link href="/blog/2015/7/warum-eigentlich-einen-extra-coach-fuer-frauen">
                Warum muss es ein Extra-Coach für Frauen sein
              </Link>
              &nbsp;und&nbsp;
              <Link href="/blog/2017/5/fundament-fuer-die-gleichberechtigung-von-frauen">
                Fundament für die Gleichberechtigung von Frauen
              </Link>).
            </em>
          </p>
          <p>
            {`Denn ja, ich bin selbst eine Frau. Eine Frau, die bereits ein wenig gelebt hat und Lebenserfahrungen in privaten und beruflichen Kontexten gesammelt hat. Selbstverständlich waren das nicht nur gute Erfahrungen. Aber ich habe diese Erfahrungen umfangreich reflektiert. Und in Krisenzeiten liegt bekanntlich eine große Chance für Entwicklung und Wachstum verborgen.`}
          </p>
          <p>
            {`Meine Leidenschaft ist es, Zusammenhänge und Verbindungen herauszuarbeiten. Denn alles hängt miteinander zusammen und beeinflusst sich gegenseitig. Ich möchte Frauen von meinen Erfahrungen profitieren lassen.`}
          </p>

          <h3>Warum ich das will?</h3>
          <p>
            {`Die Lebenszufriedenheit und Lebensbalance von Frauen sind mir ein Anliegen. Ich stehe für die Sichtbarmachung der weiblichen Potentiale und Kräfte. Frauen haben das Recht auf die gleichberechtigte Teilhabe in unserer Gesellschaft.`}
          </p>
          <p>
            {`Dazu müssen wir uns gegenseitig unterstützen.`}
          </p>
          <p>
            <em>
              {`Das macht für mich Sinn: Frauen in persönlichen Situationen des Umbruchs ganzheitlich und nachhaltig zu begleiten.`}
            </em>
          </p>

          <h3>Wie ich das mache?</h3>
          <p>
            {`Ich erkenne Sie uneingeschränkt als Expertin für Ihr Leben an. Dazu höre ich zu, frage nach, sehe hin, gebe Feedback, bestärke, mache Mut, kläre mit Ihnen Ihre Ressourcen, halte Sie fokussiert, unterstütze Sie bei Ihrer Zielfindung und Zielerreichung, bin an Ihrer Seite, wenn Sie sich Ihre Verletzungen anschauen, zeige Ihnen den Weg auf, sich langfristig und nachhaltig selbst zu steuern und ihren Selbstwert zu spüren.`}
          </p>
          <p>
            {`Denn ich möchte, dass Sie Ihre persönliche Unabhängigkeitserklärung selbstbewusst unterschreiben können.`}
          </p>
          <p>
            <strong>
              {`Haben Sie sich schon einmal gefragt, mit wem Sie gern auf eine anspruchsvolle Bergtour gehen möchten? Mit jemanden, der den Weg bereits gegangen ist oder mit jemanden der noch keine Praxiserfahrung hat. Entscheiden Sie selbst.`}
            </strong>
          </p>
          <p className='text-center'>
            <Button href="/lebensart-akademie" type="link" theme="blue">
              Online Akademie
            </Button>
            &nbsp;&nbsp;
            <Button onClick={showContactModal} theme="green">
              Erstgespräch
            </Button>
          </p>
        </Container>
      </Section>
      <Section background='white'>
        <Container>
          <h2 className='mt-0'>Weitere Artikel</h2>
          <BlogList blogEntries={linkedEntries2} />
        </Container>
      </Section>
    </>
  )
}

export default PageContentHome
