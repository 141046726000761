import type { NextPage } from 'next'
import ImgPriv from 'assets/images/content/frauencoach-privatpersonen.jpeg'
import { Layout, Main } from 'layout'
import { canonicalUrl } from 'lib/utils'
import Head from 'next/head'
import PageContentHome from 'pageContent/Home'

const Home: NextPage = () => (
  <Layout>
    <Head>
      <title>Lebensfragen klären - Krisen meistern | FrauenCoach</title>

      <meta
        name="description"
        key="description"
        content={`Coaching um Krisen zu meistern ● Ihr Coach Cordelia Schmidt in Berlin ● Identität, Beziehungen, Gesundheit, Balance, Selbstbestimmung`}
      />
      <meta
        name='keywords'
        key='keywords'
        content={`Coach Berlin, Frauen-Coach, Frauencoach, Coach für Frauen, Identität, Beziehungen, Gesundheit, Balance, Selbstbestimmung`}
      />
      <meta key="ogImage" property="og:image" content={ImgPriv?.src} />
      <meta key="twitterImage" property="twitter:image" content={ImgPriv?.src} />

      <link key="canonical" rel="canonical" href={canonicalUrl('/')} />
    </Head>
    <Main>
      <PageContentHome />
    </Main>
  </Layout>
)

export default Home
